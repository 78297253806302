@use "../config" as *;
.waf {
    &-component {
        &.waf-error-page {
            @extend %py-12;
            @extend %px-6;
            @extend %mx-2-neg;
        }
    }
    &-error-page {
        background-size: cover;
        @include bgImg("cssimages/error-page/mobile/error-bg.jpg");
        .layout-wrapper {
            @extend %flex-column;
        }
        .content {
            @extend %pt-9;
            .title {
                @extend %accent;
                @extend %font-25-pm;
                @extend %mb-0;
            }
        }
        .error-content {
            line-height: 2rem;
            @extend %pt-4;
            @extend %white;
            @extend %font-14-pr;
            &:last-child {
                @extend %pt-6;
            }
        }
        .link {
            text-decoration: underline;
            @extend %accent;
            @extend %font-14-pm;
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-error-page {
        .content {
            text-align: center;
        }
    }
}
@media screen and (min-width: $desktop-min-width) {
    .waf-error-page {
        display: flex;
        align-items: center;
        height: auto;
        min-height: calc(var(--window-inner-height) - var(--header-height));
        @include bgImg("cssimages/error-page/error-bg.jpg");
        .layout-wrapper {
            flex-direction: row;
            gap: 2rem;
        }
        .thumbnail {
            width: 50%;
        }
        .content {
            text-align: left;
            width: 50%;
            .title {
                font-size: 4.5rem;
            }
            .error-content {
                font-size: 1.6rem;
                padding-top: var(--space-2);
                &:last-child {
                    padding-top: var(--space-4);
                }
            }
            .link {
                font-size: 1.6rem;
            }
        }
    }
}